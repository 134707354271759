import { FontType } from 'src/ui/styles/settings'

const sizeMap = ['xs', 's', 'm', 'l', 'xl', '2xl', '3xl'] as const
const weightMap = ['bold', 'mid', 'regular'] as const

type FontSize = (typeof sizeMap)[number]
export type FontWeight = (typeof weightMap)[number]

export type FontStyle =
  | FontSize
  | {
      mobile: FontSize
      tablet?: FontSize
      laptop?: FontSize
      desktop?: FontSize
    }

const weightValues: { [key in FontWeight]: number } = {
  bold: 700,
  mid: 500,
  regular: 300,
}

const addFontWeight = (size: FontSize, weight: FontWeight): FontType =>
  `${size}-${weightValues[weight]}` as FontType

const getSize = (
  style: FontSize,
  changeSize: boolean,
  fontWeight: FontWeight,
): FontType => {
  const sizeIndex = sizeMap.indexOf(style)
  const boundaryIndex = sizeMap.indexOf('l')
  const hasToChangeCurrencySize = changeSize && sizeIndex >= boundaryIndex
  const hasRegularStyle = sizeIndex <= boundaryIndex

  return addFontWeight(
    hasToChangeCurrencySize ? sizeMap[sizeIndex - 2] : style,
    !hasRegularStyle ? 'bold' : fontWeight,
  )
}

export const getStyle = (
  fontStyle: FontStyle,
  fontWeight: FontWeight = 'bold',
  changeSize = false,
) => {
  if (typeof fontStyle === 'string') {
    return getSize(fontStyle, changeSize, fontWeight)
  }

  const mobile = fontStyle.mobile
  const tablet = fontStyle.tablet || mobile
  const laptop = fontStyle.laptop || tablet
  const desktop = fontStyle.desktop || laptop

  return {
    mobile: getSize(mobile, changeSize, fontWeight),
    tablet: getSize(tablet, changeSize, fontWeight),
    laptop: getSize(laptop, changeSize, fontWeight),
    desktop: getSize(desktop, changeSize, fontWeight),
  }
}
