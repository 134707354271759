import {
  getRoomInfoFromHotel,
  Hotel,
  HotelRoomSummary,
  HOTEL_ROOM_DEFAULTS,
  HOTEL_DEFAULTS,
} from 'src/core/Hotel/domain/Hotel.model'
import { container } from 'src/core/Shared/_di'

export type ImageLoader = (param: ImageLoaderParam) => string

interface ImageLoaderParam {
  src: string
  width: number
  quality?: number
}

interface ImageAspectRatio {
  width: number
  height: number
}

type ImageLoaderWithAspectRatio = (options: {
  aspectRatio: ImageAspectRatio
}) => ImageLoader

const getAdobePath = () => container.resolve('envManager').getBaseImagesUrl()
const defaultQuality = 75

export const getHotelImageLoader: ImageLoader = ({
  src,
  width,
  quality = defaultQuality,
}) => {
  const defaultSrc = HOTEL_DEFAULTS().FALLBACK_IMAGE
  const imageParameters = `?fmt=webp&wid=${width}&qlt=${quality}`

  return `${src}${imageParameters}&defaultImage=${defaultSrc}`
}

export const getExtraImageUrl = (hotelId: string | undefined, id: string) => {
  return encodeURIComponent(
    `${hotelId}-${id
      .toLowerCase()
      .replaceAll(/[#%&{}\\<>*?/$!'":@+`|=]/g, '_')}`,
  )
}

export const getExtraDefaultImageUrl = () => {
  const defaultSrc = 'default-extra'
  return `${getAdobePath()}${defaultSrc}`
}

export const getRoomImages = (room: HotelRoomSummary, hotel: Hotel) => {
  const { images } = getRoomInfoFromHotel(room, hotel)

  return images.map((src, index) => {
    return {
      src,
      alt: `Image ${index + 1} of room ${room.name}`,
    }
  })
}

export const getRoomFirstImage = (room: HotelRoomSummary, hotel: Hotel) => {
  return getRoomImages(room, hotel)[0]
}

export const getRoomImageLoader: ImageLoaderWithAspectRatio =
  ({ aspectRatio }: { aspectRatio: ImageAspectRatio }) =>
  ({ src, width, quality = defaultQuality }) => {
    const defaultSrc = HOTEL_ROOM_DEFAULTS().FALLBACK_IMAGE
    const height = calculateFromWidthAndRatio(width, aspectRatio)
    const imageParameters = `?fmt=webp&wid=${width}&hei=${height}&qlt=${quality}&fit=crop`

    return `${src}${imageParameters}&defaultImage=${defaultSrc}`
  }

export const getRoomServices = (room: HotelRoomSummary, hotel: Hotel) => {
  const { services } = getRoomInfoFromHotel(room, hotel)

  return services.map(({ id, text, icon }) => {
    return {
      id,
      text,
      icon: {
        src: icon,
        alt: `Image of service ${text}`,
      },
    }
  })
}

export const getServiceImageLoader: ImageLoader = ({
  src,
  quality = defaultQuality,
}) => {
  const defaultSrc = HOTEL_ROOM_DEFAULTS().SERVICE_SRC
  const imageParameters = `?fmt=webp&qlt=${quality}`

  return `${getAdobePath()}${src}${imageParameters}&defaultImage=${defaultSrc}`
}

const calculateFromWidthAndRatio = (
  width: number,
  { width: aspectRatioWidth, height: aspectRatioHeight }: ImageAspectRatio,
): number => {
  return Math.round((width * aspectRatioHeight) / aspectRatioWidth)
}

export const getMyBarceloBannerImageLoader: ImageLoader = ({
  src,
  width,
  quality = defaultQuality,
}) => {
  const imageParameters = `?fmt=webp&wid=${width}&qlt=${quality}`

  return `${getAdobePath()}${src}${imageParameters}`
}

export const getCampaignBannerImageLoader = ({
  src,
  quality = defaultQuality,
}: {
  src: string
  quality?: number
}) => {
  const imageParameters = `?fmt=webp&qlt=${quality}`

  return `${getAdobePath()}${src}${imageParameters}`
}
