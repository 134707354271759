import { FC } from 'react'
import { Text, TextColor } from 'src/ui/components'
import { Price as PriceModel } from 'src/core/Shared/domain/Price.model'
import { FontStyle, FontWeight, getStyle } from './Price.model'
import styles from './Price.module.scss'
import { usePriceFormatter } from 'src/ui/hooks/usePriceFormatter'
import { classNames } from 'src/ui/utils/classnames'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'

type ConditionalPrice =
  | { showAlwaysGross?: true; showAlwaysNet?: false }
  | { showAlwaysGross?: false; showAlwaysNet?: true }
interface CommonProps {
  fontStyle: FontStyle
  netPrice?: PriceModel
  grossPrice: PriceModel
  round?: 'up' | 'off'
  fontWeight?: FontWeight
  strikethrough?: boolean
  color?: TextColor
  isAbsolute?: boolean
  isNegative?: boolean
  'data-testid'?: string
  className?: string
  dataKey?: string
}

type Props = CommonProps & ConditionalPrice

export const Price: FC<Props> = ({
  fontStyle,
  netPrice,
  grossPrice,
  showAlwaysGross,
  showAlwaysNet,
  round,
  fontWeight = 'bold',
  strikethrough = false,
  isAbsolute = true,
  color = 'dark',
  isNegative = false,
  'data-testid': testId,
  className,
  dataKey,
}) => {
  const { symbolBefore, symbolAfter, getSymbol, formatValue } =
    usePriceFormatter()

  const symbol = getSymbol(grossPrice.currency)

  const getGapSize = (fontStyle: string): string => {
    return fontStyle === '3xl' ? 'xs' : 'xxs'
  }

  const getSymbolClassName = () => {
    if (typeof fontStyle === 'string') {
      return classNames(
        styles[`symbol-${getGapSize(fontStyle)}-gap`],
        symbolAfter && styles['symbol-after'],
        symbolBefore && styles['symbol-before'],
      )
    }

    const mobileGapSize = getGapSize(fontStyle.mobile)
    const tabletGapSize = fontStyle.tablet
      ? getGapSize(fontStyle.tablet)
      : mobileGapSize
    const laptopGapSize = fontStyle.laptop
      ? getGapSize(fontStyle.laptop)
      : tabletGapSize
    const desktopGapSize = fontStyle.desktop
      ? getGapSize(fontStyle.desktop)
      : laptopGapSize

    return classNames(
      styles[`mobile-symbol-${mobileGapSize}-gap`],
      styles[`tablet-symbol-${tabletGapSize}-gap`],
      styles[`laptop-symbol-${laptopGapSize}-gap`],
      styles[`desktop-symbol-${desktopGapSize}-gap`],
      symbolAfter && styles['symbol-after'],
      symbolBefore && styles['symbol-before'],
    )
  }

  const priceValue = formatValue(grossPrice, netPrice, {
    absolute: isAbsolute,
    showAlwaysGross,
    showAlwaysNet,
    roundUp: round === 'up',
    roundOff: round === 'off',
  })
  return (
    <span
      className={classNames(className, styles.container)}
      {...(isDefined(dataKey) && { [dataKey]: priceValue })}
    >
      {symbolBefore && (
        <Text
          className={getSymbolClassName()}
          color={color}
          fontStyle={getStyle(fontStyle, fontWeight, true)}
          ariaLabel={grossPrice.currency}
        >
          {symbol}
        </Text>
      )}
      <Text
        color={color}
        fontStyle={getStyle(fontStyle, fontWeight)}
        data-testid={testId}
      >
        {isNegative && '-'}
        {priceValue}
      </Text>
      {symbolAfter && (
        <Text
          className={getSymbolClassName()}
          color={color}
          fontStyle={getStyle(fontStyle, fontWeight, true)}
          ariaLabel={grossPrice.currency}
        >
          {symbol}
        </Text>
      )}
      {strikethrough && <div className={styles.strikethrough} />}
    </span>
  )
}
