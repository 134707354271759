import { FC } from 'react'
import styles from './BreakdownButton.module.scss'
import { Text } from 'src/ui/components'
import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import { classNames } from 'src/ui/utils/classnames'
import { ChevronIcon } from 'src/ui/components/atoms/Icon/_icons/navigation/ChevronIcon'

interface Props {
  text: string
  chevronDirection?: 'up' | 'down'
  onClick: () => void
  className?: string
}

export const BreakdownButton: FC<Props> = ({
  text,
  chevronDirection = 'down',
  onClick,
  className,
}) => (
  <button
    className={classNames(styles.showMoreButton, className)}
    onClick={onClick}
    type="button"
  >
    <Text
      fontStyle="link"
      color="dark"
      className={classNames(styles.showMore, 'mr-xxs')}
      centered
    >
      {text}
    </Text>
    <Icon
      icon={ChevronIcon}
      size="l"
      rotate={chevronDirection === 'down' ? 90 : -90}
    />
  </button>
)
